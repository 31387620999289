import { useState, useRef } from 'preact/hooks';

export const useNotification = () => {
	const timerId = useRef(null);
	const [notification, setNotification] = useState({
		type: null,
		message: null
	});

	const showNotification = (content) => {
		setNotification((prevState) => ({
			...prevState,
			...content
		}));

		if(timerId.current) {
			clearTimeout(timerId.current);
		};

		timerId.current = setTimeout(() => {
			timerId.current = null;

			setNotification({
				type: null,
				message: null
			});
		}, 4000);
	};

	return [notification, showNotification];
};