import style from './About.module.css';
import CardWide from '@client/components/CardWide/CardWide.jsx';
import Card from '@client/components/Card/Card.jsx';
import alphaquantsLogo from '@client/assets/alphaquants-blue.png';
import expertiseInnovationImg from '@client/assets/clipart/expertise-innovation.svg';
import advancedTradingImg from '@client/assets/clipart/advanced-trading.svg';
import empoweredStrategyImg from '@client/assets/clipart/empowering-strategy.svg';
import dataDrivenImg from '@client/assets/clipart/data-driven.svg';
import consistentPerformanceImg from '@client/assets/clipart/consistent-performance.svg';
import specializedExpertiseImg from '@client/assets/clipart/specialized-expertise.svg';

const About = ({ flipHandler }) => {
	return (
		<div class={`${style.about}`}>
			<div class={`${style.mainCardRow} row mb-4 mt-4`}>
				<div class="col-lg-8">
					<CardWide
						title="Alphaquants: Redefining Automated Trading"
						body="Alphaquants pioneers quantitative trading technology and innovative strategies tailored for futures and crypto markets. Our experienced team merges financial markets and technology expertise, dedicated to advancing automated trading."
						img={alphaquantsLogo}
					/>
				</div>
			</div>
			<div class="row gy-4">
				<div class="col-lg-4">
					<Card
						title="Expertise and Innovation"
						body="Founded by industry veterans, our proprietary technology and strategies thrive across diverse market conditions, including high volatility scenarios. Our commitment to innovation drives continuous refinement and algorithmic development for profitable investment strategies."
						img={expertiseInnovationImg}
					/>
				</div>
				<div class="col-lg-4">
					<Card
						title="Advanced Trading Solutions"
						body="Our platform features smart order routing and swift multi-venue execution, seamlessly supporting traditional and digital asset trading. This agility ensures efficient trades within milliseconds."
						img={advancedTradingImg}
					/>
				</div>
				<div class="col-lg-4">
					<Card
						title="Empowering Strategy Engine"
						body="At the core lies our strategy engine, enabling design, backtesting, and execution of strategies using historical and real-time market data. It efficiently transmits signals for execution, informed by data-driven, systematic approaches."
						img={empoweredStrategyImg}
					/>
				</div>
				<div class="col-lg-4">
					<Card
						title="Data-Driven Precision"
						body="Meticulously crafted, our strategies leverage data analytics, emphasizing empirical evidence and statistical probabilities. Our multi-asset, momentum-based strategies prioritize risk and volatility management, maximizing profits while minimizing drawdowns."
						img={dataDrivenImg}
					/>
				</div>
				<div class="col-lg-4">
					<Card
						title="Consistent Performance"
						body="Our 'multi-asset' approach, anchored in data analytics and risk management, targets consistent returns while mitigating drawdowns over the long term."
						img={consistentPerformanceImg}
					/>
				</div>
				<div class="col-lg-4">
					<Card
						title="Specialized Expertise"
						body="Our success hinges on the collective expertise in trading, technology, quantitative analysis, and software development. This blend keeps us at the forefront of innovative strategies in the financial sector."
						img={specializedExpertiseImg}
					/>
				</div>
			</div>
			<button class={`${style.contactBtn} btn btn-dark`} onClick={flipHandler}>Get In Touch <i class="fa-solid fa-angle-right"></i></button>
		</div>
	);
};

export default About;