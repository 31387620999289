import About from '@client/pages/About/About.jsx';
import Contact from '@client/pages/Contact/Contact.jsx';
import style from './InfoContainer.module.css';

const InfoContainer = () => {
	const handleFlip = () => {
		const flipper = document.querySelector('.flipper');
		flipper.classList.toggle(style.flip);
	};

	return (
		<div class={style.infoContainer}>
			<div class={`${style.flipper} flipper`}>
				<div class={`${style.front} container`}>
					<About
						flipHandler={handleFlip}
					/>
				</div>
				<div class={`${style.back} container`}>
					<Contact
						flipHandler={handleFlip}
					/>
				</div>
			</div>
		</div>
	);
};

export default InfoContainer;