import { render } from 'preact';
import { LocationProvider, Router, Route } from 'preact-iso';
import Landing from './pages/Landing/Landing.jsx';
import NotFound from './pages/_404.jsx';
import 'bootstrap';
import '@fortawesome/fontawesome-free/js/all.js';
import './scss/styles.scss';

const App = () => {
	return (
		<LocationProvider>
			<main>
				<Router>
					<Route path="/" component={Landing} />
					<Route default component={NotFound} />
				</Router>
			</main>
		</LocationProvider>
	);
};

render(<App />, document.getElementById('app'));