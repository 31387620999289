import style from './Card.module.css';

const Card = ({ title, body, img }) => {
	return (
		<div class={style.card}>
			<div class="card">
				<h5 class="card-title">{title}</h5>
				<div class="row h-100 g-0">
					<div class="col-4 gx-4 d-flex align-items-center">
						<img src={img} class="img-fluid rounded-start" alt={title} />
					</div>
					<div class="col-8 d-flex">
						<div class="card-body ps-0 d-flex align-items-center">
							<p class="card-text">{body}</p>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default Card;