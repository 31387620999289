import style from './Notification.module.css';

const Notification = ({ type, message }) => {

	// Add 'alert-dismissible' to the class list if adding the close button.
	return (
		<div class={`${style.notification} alert alert-${type} fade show`} role="alert">
			{message}
			{/* <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button> */}
		</div>
	);
};

export default Notification;