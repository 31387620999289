import style from './CardWide.module.css';

const CardWide = ({ title, body, img }) => {
	return (
		<div class={style.cardWide}>
			<div class="card">
				<img src={img} class="card-img-top" alt={title} />
				<div class="card-body">
					<h5 class="card-title">{title}</h5>
					<p class="card-text">{body}</p>
				</div>
			</div>
		</div>
	);
};

export default CardWide;