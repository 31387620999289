import { useEffect, useState, useCallback, useLayoutEffect, useRef } from 'preact/hooks';
import { memo } from 'preact/compat';
import { loadFull } from "tsparticles";
import Particles, { initParticlesEngine } from "@tsparticles/preact";
import { loadPolygonMaskPlugin } from "@tsparticles/plugin-polygon-mask";
import particlesConfig from '@client/config/particles.config.json';
import style from './Landing.module.css';
import InfoContainer from '@client/pages/InfoContainer/InfoContainer';

const MemoizedParticles = memo(({ options }) => <Particles options={options} />);

const Landing = () => {
	const [particlesInitialized, setParticlesInitialized] = useState(false);
	const [showAboutSection, setShowAboutSection] = useState(false);
	const sectionRef = useRef(null);

	useLayoutEffect(() => {
		initParticlesEngine(async engine => {
			await loadPolygonMaskPlugin(engine);
			await loadFull(engine);
		}).then(() => {
			setParticlesInitialized(true);
		});
	}, []);

	useEffect(() => {
		setTimeout(() => {
			unhideOnLoad();
		}, 1000);
	}, [particlesInitialized]);

	const unhideOnLoad = () => {
		if(!particlesInitialized || !sectionRef.current) return;

		sectionRef.current.style.visibility = 'visible';
	};

	const handleTransition = useCallback(() => {
		setShowAboutSection(prevState => !prevState);
	}, [document]);

	return (
		<div class={style.landing}>
			{particlesInitialized && (
				<>
					<MemoizedParticles options={particlesConfig} />
					<div class={`${style.info} ${showAboutSection ? style.animateUp : style.animateDown}`} ref={sectionRef}>
						<InfoContainer />
					</div>

					<button class="btn btn-dark" onClick={handleTransition}>{showAboutSection ? 'Close' : 'Learn More'}</button>
				</>
			)}
		</div>
	);
};

export default Landing;