const NotFound = () => {
	return (
		<section>
			<h1>404: Not Found</h1>
			<p>It's gone :(</p>
		</section>
	);
};

export default NotFound;
