import { useCallback, useRef, useState } from 'preact/hooks';
import { useNotification } from '@client/hooks/useNotification';
import Notification from '@client/components/Notification/Notification';
import style from './Contact.module.css';

const Contact = ({ flipHandler }) => {
	const emailRef = useRef(null);
	const messageRef = useRef(null);
	const [email, setEmail] = useState(null);
	const [message, setMessage] = useState(null);
	const [notification, showNotification] = useNotification();

	const handleValidation = () => {
		if (!email) {
			emailRef.current.classList.toggle('is-invalid');
		}

		if (!message) {
			messageRef.current.classList.toggle('is-invalid');
		}
	};

	const handleEmailChange = useCallback((e) => {
		if (!e.target.value) {
			return setEmail(null);
		}

		const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
		const isValidEmail = emailRegex.test(e.target.value);

		if (!isValidEmail) {
			return emailRef.current.classList.add('is-invalid');
		}
		
		emailRef.current.classList.remove('is-invalid');
		setEmail(e.target.value);
	}, []);

	const handleMessageChange = useCallback((e) => {
		setMessage(e.target.value);
	}, []);

	const handleSubmit = async () => {
		if (!email || !message) {
			console.warn('Contact handleSubmit - missing email or message');
			return showNotification({
				type: 'danger',
				message: 'Please fill out both fields.'
			});
		};

		fetch('/send-email', {
			method: 'POST',
			headers: {
				'Content-Type': 'application/json',
			},
			body: JSON.stringify({
				email: email,
				message: message
			}),
		}).then(res => {
			if(res.status !== 200) {
				return showNotification({
					type: 'danger',
					message: 'Failed to send message. Please try again later.'
				});
			}

			showNotification({
				type: 'success',
				message: 'Your message has been sent!'
			});
		}).catch(err => {
			console.error(err);
			showNotification({
				type: 'danger',
				message: 'Failed to send message. Please try again later.'
			});
		});
	};

	return (
		<div class={style.contact}>
			{notification.message && <Notification type={notification.type} message={notification.message} /> }
			<div class="row">
				<div class="col-md-10">
					<form>
						<div class="mb-3 w-25">
							<label for="contactFormEmail" class="form-label">Email</label>
							<input ref={emailRef} onChange={handleEmailChange} type="email" class="form-control" id="contactFormEmail" />
						</div>
						<div class="mb-3">
							<label for="contactFormMessage" class="form-label">Message</label>
							<textarea ref={messageRef} onChange={handleMessageChange} class="form-control" id="contactFormMessage" rows={6}></textarea>
						</div>
						<div class={style.btnRow}>
							<button class={`${style.backBtn} btn btn-dark`} onClick={flipHandler} type="button"><i class="fa-solid fa-angle-left"></i> Back</button>

							<div
								class={style.submitBtnWrapper}
								onMouseEnter={handleValidation}
								onMouseLeave={handleValidation}
								disabled={false}
							>
								<button
									class="btn btn-info"
									type="button"
									disabled={!email || !message}
									onClick={handleSubmit}
								>
									Send message <i class="fa-regular fa-envelope"></i>
								</button>
							</div>
						</div>
					</form>
				</div>
			</div>
		</div>
	);
};

export default Contact;